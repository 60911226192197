<template>
  <div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="服务商名称" v-if="form">
        <div>{{ form.name }}</div>
      </a-form-item>
      <a-form-item label="国际化标准列表:">
        <a-table bordered :data-source="showI18n" :columns="$constRepository.serviceProvider.countryColumns">
          <template v-for="col in ['nameMessage']" :slot="col" slot-scope="text, record">
            <div :key="col">
              <a-input
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                @change="(e) => handleChangeI18n(e.target.value, record.key, col)"
              ></a-input>
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
          <template slot="operation" slot-scope="text, record">
            <div>
              <span v-if="record.editable">
                <a @click="() => saveI18n(record.key)">保存</a>
              </span>
              <span v-else>
                <a
                  v-if="$hasPermissions(['rcs:i18n:create'])"
                  :disabled="editingKey !== ''"
                  @click="() => editI18n(record.key)"
                  >编辑</a
                >
              </span>
              <a-divider type="vertical" />
              <a-popconfirm
                okText="是"
                cancelText="否"
                title="是否删除国际化?"
                @confirm="() => onDeleteI18n(record.key)"
              >
                <a v-if="$hasPermissions(['rcs:i18n:create'])" href="javascript:;">删除</a>
              </a-popconfirm>
            </div>
          </template>
        </a-table>
      </a-form-item>
      <a-form-item class="group-btn">
        <a-button type="primary" @click="createI18n">确定</a-button>
        <a-button class="creator-btn" @click="cancel">返回</a-button>
      </a-form-item>
    </a-form-model>
  </div>
</template>

<script>
// import lodash from 'lodash';

export default {
  name: 'ServiceProviderI18n',
  components: {},
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      filterSelected: ['服务商名称'],
      filterOptions: ['服务商名称', '服务商地址'],
      form: {},
      editingKey: '',
      country: [],
      showI18n: [],
      i18nForm: {
        relationId: '',
        i18n: [],
      },
      i18n: [],
    };
  },
  created() {
    this.getSupport();
    this.form = this.$route.params.serviceProvider;
    if (!this.form) {
      this.$router.back();
    } else {
      this.listI18n();
    }
  },
  mounted() {},
  methods: {
    async getSupport() {
      const response = await this.$apiManager.i18n.getSupport();
      this.country = response.data;
    },
    async listI18n() {
      const response = await this.$apiManager.i18n.listI18n('serviceProviders', this.form.serviceProviderId);
      this.i18n = response.data;
      this.mergeI18n();
    },
    createI18n() {
      this.splitI18n();
      this.$apiManager.i18n
        .editI18n('serviceProviders', this.i18nForm)
        .then(() => {
          this.$message.success('修改成功');
        })
        .catch(() => {});
    },
    splitI18n() {
      this.i18nForm.i18n = [];
      this.showI18n.forEach((i18n) => {
        if (i18n.nameMessage) {
          this.i18nForm.i18n.push({ field: 'name', language: i18n.name, message: i18n.nameMessage });
        }
        if (i18n.remarkMessage) {
          this.i18nForm.i18n.push({ field: 'remark', language: i18n.name, message: i18n.remarkMessage });
        }
      });
      this.i18nForm.relationId = this.form.serviceProviderId;
    },
    mergeI18n() {
      this.country.forEach((value) => {
        this.showI18n.push(value);
      });
      this.showI18n.forEach((param, index) => {
        this.$set(param, 'key', index);
        this.i18n.forEach((i18ns) => {
          if (i18ns.language === param.name) {
            this.$set(param, `${i18ns.field}Message`, i18ns.message);
          }
        });
      });
    },
    onDeleteI18n(key) {
      this.showI18n[key].nameMessage = '';
      this.showI18n[key].remarkMessage = '';
      // this.showI18n = this.onDelete(this.showI18n, key);
    },
    onDelete(data, key) {
      const dataSource = [...data];
      return dataSource.filter((item) => item.key !== key);
    },
    saveI18n(key) {
      this.showI18n = this.save(this.showI18n, key);
    },
    save(data, key) {
      const cacheData = data.map((item) => ({ ...item }));
      // this.cacheData = this.form.keys.map((item) => ({ ...item }));
      const newData = [...data];
      const newCacheData = [...cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        Object.assign(targetCache, target);
        this.editingKey = '';
        return newData;
      }
      return null;
    },
    edit(data, key) {
      const newData = [...data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        this.$set(target, 'editable', true);
      }
    },
    editI18n(key) {
      this.edit(this.showI18n, key);
    },
    handleChangeBtn(data, value, key, column) {
      const newData = [...data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        return newData;
      }
      return null;
    },
    handleChangeI18n(value, key, column) {
      this.showI18n = this.handleChangeBtn(this.showI18n, value, key, column);
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.edit-btn {
  color: green;
}
.delete-btn {
  color: red;
}
.creator-btn {
  margin-left: 5%;
}
.service-provicer-operations {
  display: flex;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .service-table {
    margin-top: 2%;
  }
}
.group-btn {
  margin-left: 16.7%;
}
.editable-add-btn {
  float: right;
  margin: 5px 0px;
}
</style>
